import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const DEFAULT_LOADER_INDEX = 'LogiLab';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.loadingSubject.asObservable();
  private loadingStack: string[] = [];

  /**
   * Starts the loading process for a specific loader identified by the provided loaderId.
   * If the loaderId does not already exist in the loading stack, it is added to the stack, and the loading state is updated.
   * If the loaderId already exists in the loading stack, no duplicate entry is added.
   *
   * @param loaderId The identifier of the loader to start loading for. Defaults to 'LogiLab' if not provided.
   */
  startLoading(loaderId: string = DEFAULT_LOADER_INDEX): void {
    const index = this.loadingStack.indexOf(loaderId);
    if (index == -1) this.loadingStack.push(loaderId);
    this.updateLoadingState();
  }

  /**
  * Stops the loading process for a specific loader identified by the provided loaderId.
  * If the loaderId exists in the loading stack, it is removed from the stack, and the loading state is updated.
  * If the loaderId does not exist in the loading stack, no action is taken.
  *
  * @param loaderId The identifier of the loader to stop loading for. Defaults to 'LogiLab' if not provided.
  */
  stopLoading(loaderId: string = DEFAULT_LOADER_INDEX): void {
    const index = this.loadingStack.indexOf(loaderId);
    if (index > -1) {
      this.loadingStack.splice(index, 1);
    }
    this.updateLoadingState();
  }

  /**
   * Updates the loading state based on the length of the stack.
   */
  private updateLoadingState(): void {
    this.loadingSubject.next(this.loadingStack.length > 0);
  }

  /**
   * Clears all loaders from the loading stack and updates the loading state.
   */
  clearAllLoaders(): void {
    this.loadingStack = [];
    this.updateLoadingState();
  }
}
